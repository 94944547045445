<template>
  <div>
    <div class="Section">
      <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span  @click="FirstClickFun()">{{ clickVal }} </span>
        </div>
      </div>
      <!-- <div class="sortBox">
        <div class="sortUlbox">
          <ul class="sortUl" >
            <li
              v-for="(item, index) in listdata"
              :key="index"
              @click="listdataAdd(item, index)"
              :class="{ active: Boolean(checkFirst === index) }"
            >
              {{ item.categoryName }}
            </li>
          </ul>
        </div>
      </div> -->
      <!-- <ul class="filterUl">
        <li
          v-for="(item, index) in filterItems"
          :key="index"
          @click="filterFun(item,index)"
          :class="{ active: Boolean(filterType === index) }"
        >
          {{ item.sort }}
          <i
            :class="
              item.filterMark == 'price' ? Boolean(item.show === 1)?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop':Boolean(filterType === index)?'active el-icon-bottom':'el-icon-bottom'
            "
            aria-hidden="true"
          ></i>
           <i v-if="item.filterMark == 'price'"
            :class="Boolean(item.show === 2)?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
            aria-hidden="true"
          ></i>
        </li>
        <li>
          <el-checkbox v-model="checked" @change="filterFun()"
            >仅显示有货</el-checkbox
          >
        </li>
      </ul> -->
      <div class="goodsList"  v-if="searchProducts.length>0" >
        <ItemCombo :productType="clickID" v-for="(item,index) in searchProducts" :key="index"  class="goods" :info="item"></ItemCombo>
      </div>
      <div class="pageBox">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 获取到的一二级分类数据
const ItemCombo=()=>import('components/index/ItemCombo.vue');
// import {GetProducts} from 'api/product.js';
import {selectCategortyList,promotionList} from 'api/activity.js';

// import Qs from 'qs'
export default {
  name: "comboType",
  inject:['reload'],
  data() {
    return {
      total: 0,
      current: 1, //当前页
      size: 10, //每页数量
      searchProducts: '', //初始商品数据
      clickVal:'', //  面包屑 初始，一级分类选中值为空
      clickID: '',    //一级分类ID 
      checkFirst: 0, //    一级分类，tab切换，初始值为0
      Medicareflag: true, //医保分类列表  初始值为展示状态
      checked: false, //筛选checked 选中状态  初始为选中
      filterType:'',
      sort:0,//传给后台的搜索值
      filterItems: [
        { sort: "销量", show: false, filterMark: "sell" },
        { sort: "价格",show:false, filterMark: "price" },
        { sort: "上架时间", show: false, filterMark: "rise" },
      ],
      listdata: "",   //分类数据
      parmesData:{}//初始搜索值传参
    };
  },
  created() {
    // 获取分类数据，改变data，分类数据赋值
    selectCategortyList().then(res=>{
           this.listdata=res.data.data;
           let type=this.$route.query.comboType;
           if(type==1){
              this.clickVal = '畅想易购';
              this.clickID='AconnectB'
              this.parmesData.current = 1; //当前页
              this.parmesData.size = 10; //每页数量
              this.parmesData.categoryId='';
              this.parmesData.order='';
              // 还有一个是查商品列表的，传入分类id，还有排序order，order为1：销量排序，2：价格排序，3：上架时间排序
              // a+b
              this.getProducts(this.parmesData)

            }else{
               this.clickVal = '超值套餐';
               this.clickID='setMeal'
              //  套餐
                this.getProducts(this.parmesData)
            }
    });
   
  },

  methods: {
    handleCurrentChange(val) {
      this.current = val;
      this.parmesData.current = this.current; //当前页
      sessionStorage.setItem('current',this.current);
      this.parmesData.size = this.size; //每页数量
       // 传递参数
      this.getProducts(this.parmesData);
    },
    getProducts(datas){
        let type=this.$route.query.comboType;
           if(type==1){
            //  畅想易购
              // let datas={
              //   categoryId:'',
              //   current:'1',
              //   order:3,
              //   size:5
              // }
              promotionList(datas).then(res=>{
                this.searchProducts=res.data.data.records;
                console.log(this.searchProducts)
                this.total=Number(res.data.data.total);
              })
            }else{
              // 超值套餐
              // this.listdata.forEach((item,index)=>{
              //   if(item.name==localStorage.getItem('clickVal')){
              //     this.checkFirst=index
              //     this.thisIndex = index;
              //   }
              // })
            }
    },
    // 点击筛选条件，获取商品数据渲染
    filterFun(item,index) {
      // console.log(index)
      if (item) {
          this.sort=index+1
          this.filterType=index
          // alert(this.sort)
           // 还有一个是查商品列表的，传入分类id，还有排序order，order为1：销量排序，2：价格排序，3：上架时间排序
            this.parmesData.order = this.sort; //搜索条件
            this.getProducts(this.parmesData);
      }else {
        //显示有货
        if (this.checked == false) {
           this.parmesData.isHaveStock = 0; //否
            this.getProducts(this.parmesData);
        } else {
          //显示有货
            this.parmesData.isHaveStock = 1; //是
            this.getProducts(this.parmesData);
        }
      }
    },
    // 一级分类 点击事件
    listdataAdd(lists, index) {


      if(lists.id==-1){
        this.parmesData.current = 1; //当前页
              this.parmesData.size = 10; //每页数量
              this.parmesData.categoryId='';
              this.parmesData.order='';
              // 还有一个是查商品列表的，传入分类id，还有排序order，order为1：销量排序，2：价格排序，3：上架时间排序
              // a+b
              this.getProducts(this.parmesData)
      }else{
        console.log(lists)
        this.checkFirst = index;
        lists.active = true;
        this.parmesData={
              categoryId:lists.id,   //"商品分类id
        };
        this.parmesData.current = 1; //当前页
          this.current= this.parmesData.current
        this.parmesData.size = 10; //每页数量
        // 传递参数
        this.getProducts(this.parmesData);
      }
      
    },

    // 点击当前位置一级分类
    FirstClickFun(){
      this.reload();
    }
  },
  watch: {
    "$route.query.comboType": function() {
    },
  },
  components: {
    ItemCombo,
  },

  mounted() {
  },
   updated() {
   }
};
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #000;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}
@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  width: 100%;
  float: left;
  margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
         cursor: pointer;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ffe76f;
          color: #ffe76f;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
          cursor: pointer;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    // margin-bottom: 17px;
    border: 1px solid #eeeeee;
    border-bottom:none;
    width: 100%;
    float: left;
    .sortUlbox:last-child{
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom:1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #999;
          font-size: 14px;
          font-weight: bold;
           cursor: pointer;
            &:hover{
                color: #FFD303;
            }
        }
        li.active {
          color: #FFD303;
        }
      }
    }
  }

  .filterUl {
    padding: 8px 15px;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    box-sizing: content-box;
    background-color: #ffffff;
    height: 20px;
    line-height: 20px;
    width: 100%;
    float: left;
    
    li.active {
      color: #ffe76f;
    }
    li {
      float: left;
      margin-right: 40px;
      color: #666666;
      font-size: 12px;
       cursor: pointer;
            &:hover{
                color: #FFD303;
                  .el-checkbox{
                  color: #FFD303;
                }
            }
      i {
        font-weight: bolder;
        font-size: 14px;
      }
    }
  }
  .goodsList {
    height: auto;
    width: 100%;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    .goods:nth-child(5n + 0){
      margin-right: 0px;
    }
  }
}
</style>
